<template>
  <div
    class="bg-white shadow sm:rounded-lg mx-4 flex justify-between items-center"
  >
    <div class="w-full">
      <SectionHeader label="Filter" :loading="loading" />

      <form class="sm:flex sm:items-center px-3 pb-6">
        <div class="w-full">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <label class="block text-sm font-medium text-gray-700"
                >With Recordings Only</label
              >
              <div class="mt-2">
                <div class="flex space-x-2">
                  <Switch
                    v-model="recordingOnly"
                    :class="[
                      recordingOnly ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                    ]"
                  >
                    <span class="sr-only">With Recordings Only</span>
                    <span
                      aria-hidden="true"
                      :class="[
                        recordingOnly ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                      ]"
                    />
                  </Switch>
                </div>
              </div>
            </div>
            <div class="col-span-4">
              <label
                for="first_name"
                class="block text-sm font-medium text-gray-700"
                >Session</label
              >
              <input
                type="text"
                v-model="session"
                autocomplete="session"
                class="
                  mt-1
                  h-9
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
            <div class="col-span-4">
              <label
                for="first_name"
                class="block text-sm font-medium text-gray-700"
                >Number</label
              >
              <input
                type="text"
                v-model="number"
                autocomplete="number"
                class="
                  mt-1
                  h-9
                  focus:ring-indigo-500 focus:border-indigo-500
                  block
                  w-full
                  shadow-sm
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4 mt-4">
            <div class="col-span-8">
              <label
                for="first_name"
                class="block text-sm font-medium text-gray-700 mb-1"
                >Date Range</label
              >
              <v-date-picker v-model="range" is-range>
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex justify-center items-center">
                    <input
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      class="
                        border
                        h-9
                        px-2
                        py-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                    <svg
                      class="w-4 h-4 mx-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                    <input
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      class="
                        border
                        h-9
                        px-2
                        py-1
                        focus:ring-indigo-500 focus:border-indigo-500
                        w-full
                        shadow-sm
                        sm:text-sm
                        border-gray-300
                        rounded-md
                      "
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>

            <div class="col-span-2">
              <button
                :disabled="loading"
                @click="filter"
                type="button"
                class="
                  mt-6
                  h-9
                  w-full
                  inline-flex
                  items-center
                  justify-center
                  px-4
                  py-2
                  border border-transparent
                  shadow-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-indigo-600
                  hover:bg-indigo-700
                  focus:outline-none
                  sm:w-auto sm:text-sm
                "
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import SectionHeader from "@/modules/common_views/section_header.vue";
import { mapGetters, mapActions } from "vuex";
import { Switch } from "@headlessui/vue";
export default {
  components: {
    SectionHeader,
    Switch,
  },
  data() {
    return {
      range: {
        start: new Date(),
        end: new Date(),
      },
      session: "",
      number: "",
      recordingOnly: true,
    };
  },
  created() {
    this.range.start.setDate(this.range.end.getDate() - 7);
    this.filter();
  },
  methods: {
    filter() {
      if (this.loading) return;

      let from = this.getFormattedDate(this.range.start) + " 00:00:00";
      let to = this.getFormattedDate(this.range.end) + " 23:59:59";

      this.setFilterValues({
        term: this.session.toLowerCase().trim().replace("session", "ses"),
        number: this.number,
        from,
        to,
        recordingOnly: this.recordingOnly ? 1 : 0,
      });
      this.listSessions({ recordingOnly: this.recordingOnly ? 1 : 0 });
    },
    getFormattedDate(d) {
      return (
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2)
      );
    },
    ...mapActions("RecordingsStore", ["listSessions", "setFilterValues"]),
  },
  computed: {
    ...mapGetters("RecordingsStore", ["loading"]),
  },
};
</script>
