<template>
  <div
    class="
      mt-3
      bg-white
      shadow
      sm:rounded-lg
      mx-4
      flex
      justify-between
      items-center
      cursor-pointer
      hover:bg-gray-50
    "
  >
    <div class="w-full">
      <div class="px-3 py-4 flex justify-between items-center">
        <div
          class="
            text-base
            leading-6
            font-medium
            text-gray-800
            uppercase
            w-full
            flex
            items-center
          "
        >
          <span>{{ session.name.replace("ses", "session") }}</span>
          <span class="text-gray-600 text-sm ml-2"
            >{{ session.calls_count }} calls</span
          >
          <span class="text-gray-600 ml-2">·</span>
          <span class="text-gray-600 text-sm ml-2">At {{ created }}</span>

          <span class="text-gray-600 ml-2">·</span>
          <span class="text-gray-600 text-sm ml-2"
            >By
            {{
              session.main_call.caller_id_name &&
              session.main_call.caller_id_name.length !== 0
                ? session.main_call.caller_id_name
                : "N/A"
            }}</span
          >
        </div>
        <span class="relative inline-block">
          <ChevronRightIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ChevronRightIcon,
  },
  props: {
    session: {
      type: Object,
      default: null,
    },
  },

  computed: {
    created() {
      const date = new Date(this.session.created_at * 1000);
      return date.toISOString().split("T")[0];
    },
  },
};
</script>
