<template>
  <Filter />

  <div class="mt-4">
    <router-link
      :to="'/session/' + session.id"
      v-for="session in sessions"
      :key="session.id"
    >
      <Item :session="session" />
    </router-link>
  </div>

  <div v-if="sessions.length === 0" class="mx-3 mb-2">
    <EmptyTable label="You do not have any sessions." />
  </div>

  <div v-if="sessions.length !== 0" class="mx-4 my-4">
    <Pagination />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Filter from "@/modules/recordings/views/recordings_filter.vue";
import Item from "@/modules/recordings/views/recordings_item.vue";
import Pagination from "@/modules/recordings/views/recordings_pagination.vue";
import EmptyTable from "@/widgets/common/EmptyTable.vue";

export default {
  components: {
    Filter,
    Item,
    Pagination,
    EmptyTable,
  },
  methods: {
    ...mapActions("RecordingsStore", ["listSessions"]),
  },
  computed: {
    ...mapGetters("RecordingsStore", ["sessions"]),
  },
};
</script>
